<template>
  <div>
    <el-form inline size="small">
      <el-form-item label="Ticket Number">
        <el-input
          v-model="form.workNumber"
          clearable
          placeholder="Input"
          @keyup.native.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="Agent">
        <el-select
          v-model="form.agent"
          clearable
          filterable
          multiple
          placeholder="Select"
        >
          <el-option
            v-for="item in customerServiceList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="QA Agent">
        <el-select
          v-model="form.qaAgent"
          clearable
          filterable
          placeholder="Select"
        >
          <el-option
            v-for="item in qaList"
            :key="item.agent + item.group"
            :label="item.agent"
            :value="item.agent"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Modification Time">
        <el-date-picker
          v-model="form.date"
          :editable="false"
          align="center"
          end-placeholder="End"
          range-separator="~"
          start-placeholder="Start"
          type="daterange"
          unlink-panels
          value-format="yyyy-MM-dd"
        />
      </el-form-item>
      <el-form-item label="">
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          Search
        </el-button>
        <el-button @click="handleReset">Reset</el-button>
      </el-form-item>
    </el-form>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <vxe-column field="workNumber" title="Ticket Number" />
      <vxe-column field="agent" title="Agent" width="100" />
      <vxe-column field="score" title="Score" width="80" />
      <vxe-column field="scoreNature" title="Score Nature" width="120">
        <template #default="{row}">
          {{ getScoreNature(row.scoreNature) }}
        </template>
      </vxe-column>
      <vxe-column field="modifyTime" title="Modification Time" />
      <vxe-column field="qaAgent" title="QA Agent" width="100" />
      <vxe-column field="version" title="Version" width="100" />
      <vxe-column title="Operation">
        <template #default="{row}">
          <el-button type="text" @click="handleOpenScoreDialog(row)">
            View Detail
          </el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
    <score-dialog
      :row.sync="currentRow"
      :visible.sync="scoreDialogVisible"
      type="detail"
    />
  </div>
</template>

<script>
import { debounceGetTableMaxHeight } from '@/utils'
import Paging from '@/components/Paging/index.vue'
import { getLogList } from './apis/list'
import { getCustomerServiceList } from '../work-order-quality-inspection/apis/list'
import ScoreDialog from '../work-order-quality-inspection/ScoreDialog.vue'
import { roleEnum, scoreNatureMap } from '../const'
import { getQualityAgentList } from '../customer-service/apis/list'
import { statusEnum as roleStatusEnum } from '../customer-service/const'

export default {
  name: 'LogList',
  components: {
    Paging,
    ScoreDialog
  },
  data() {
    return {
      customerServiceList: [],
      qaList: [],
      form: {
        workNumber: '',
        agent: [],
        qaAgent: '',
        date: []
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      queryLoading: false,
      maxHeight: 0,
      tableData: [],
      currentRow: null,
      scoreDialogVisible: false
    }
  },
  computed: {
    queryParams() {
      const [modifyTimeStart, modifyTimeEnd] = this.form.date || []
      return {
        ...this.form,
        ...this.pager,
        workNumber: this.form.workNumber.trim(),
        agent: this.form.agent.join(','),
        date: undefined,
        modifyTimeStart,
        modifyTimeEnd
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    }
  },
  created() {
    this.getQAList()
    this.getCustomerServiceList()
    this.handleQuery()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async getQAList() {
      const { datas } = await getQualityAgentList({
        delFlag: roleStatusEnum['启用'],
        role: roleEnum['质检员'],
        current: 1,
        size: 1000
      })
      const { records } = datas || {}
      if (records?.length) {
        this.qaList = records.filter((e, i, a) => {
          // 根据 agent 去重
          return a.findIndex(item => item.agent === e.agent) === i
        })
      }
    },
    getScoreNature(val) {
      return scoreNatureMap[val]
    },
    handleOpenScoreDialog(row) {
      this.currentRow = {
        id: row.qualityId
      }
      this.scoreDialogVisible = true
    },
    async getCustomerServiceList() {
      const { datas } = await getCustomerServiceList()
      this.customerServiceList = datas || []
    },
    async handleQuery() {
      this.queryLoading = true
      if (this.controller) {
        this.controller.abort()
        setTimeout(() => {
          this.queryLoading = true
        })
      }
      this.controller = new AbortController()
      const signal = this.controller.signal
      if (this.queryChange) {
        this.pager.current = 1
      }
      const { datas } = await getLogList(this.queryParams, signal).finally(() => {
        this.queryLoading = this.queryChange = false
      })
      this.tableData = datas?.records || []
      this.pager.total = datas?.pager?.total || 0
    },
    handleReset() {
      this.form = {
        workNumber: '',
        agent: [],
        qaAgent: '',
        date: []
      }
      this.handleQuery()
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
